@media print {
  @page {
    size: landscape;
  }
  body * {
    visibility: hidden;
    print-color-adjust: unset;
    color: black;
  }
}
